<template>
  <div
    class="section-blog-post-content py-5"
    style="background-color: var(--white) !important"
  >
    <div
      class="page-padding d-flex flex-row justify-content-center"
      :style="`background-color: var(--white) !important;`"
    >
      <div class="padding-vertical padding-xhuge container-large">
        <div class="d-flex flex-row justify-content-center mb-3">
          <h1
            style="
              color: var(--dark-brown);
              font-weight: 300;
              font-size: 60px;
              line-height: 1.1;
            "
          >
            {{ vars.guideTitleText }}
          </h1>
        </div>
        <div class="row g-5 tw-mt-4 lg:tw-mt-12 d-flex justify-content-between">
          <div class="col-lg-7 pt-3">
            <lazy-library-form v-if="form" :form="form" :component="component" />
          </div>
          <div class="col-lg-5">
            <div class="image-wrapper blog h-100">
              <img
                alt=""
                loading="lazy"
                style="
                  -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1)
                    rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                  -moz-transform: translate3d(0, 0, 0) scale3d(1, 1, 1)
                    rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                  -ms-transform: translate3d(0, 0, 0) scale3d(1, 1, 1)
                    rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                  transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0)
                    rotateY(0) rotateZ(0) skew(0, 0);
                "
                :src="vars.guideImage"
                class="cover-image"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'GuideDetail',

  mixins: [ComponentMixin],

  data() {
    return {
      form: null,
    };
  },
  created() {
    try {
      const form = getVariable(this.component, 'guide-form') as string;
      const parsedForm = JSON.parse(form);
      if (form && Object.keys(parsedForm).length) {
        this.form = parsedForm;
      }
    } catch (e) {}
  },
});
</script>

<script setup lang="ts"></script>

<style scoped>
@media screen and (max-width: 479px) {
  .image-wrapper.blog {
    width: unset;
  }
}
</style>
