<template>
  <section
    v-if="property"
    class="section-product-content pb-5"
    style="background-color: var(--white)"
  >
    <div class="page-padding">
      <div class="container-large">
        <div
          class="tw-flex tw-flex-row tw-items-center tw-overflow-x-auto"
          style="
            background-color: rgba(234, 228, 215, 0.25);
            border: solid var(--seashell);
            border-width: 1px 1px 0 1px;
          "
        >
          <h4
            class="heading-small py-3 px-4 n-cursor-pointer"
            :class="activeTab === 1 ? 'active-tab' : ''"
            style="font-size: 20px"
            @click="changeTab(1)"
          >
            Property Info
          </h4>
          <h4
            :class="activeTab === 2 ? 'active-tab' : ''"
            class="heading-small px-4 py-3 n-cursor-pointer"
            style="font-size: 20px"
            @click="changeTab(2)"
          >
            Map
          </h4>
          <h4
            v-if="property.floor_plans && property.floor_plans.length > 0"
            :class="activeTab === 3 ? 'active-tab' : ''"
            class="heading-small px-4 py-3 n-cursor-pointer"
            style="font-size: 20px"
            @click="changeTab(3)"
          >
            Floor Plan
          </h4>
          <h4
            v-if="property.epcs && property.epcs.length > 0"
            :class="activeTab === 4 ? 'active-tab' : ''"
            class="heading-small px-4 py-3 n-cursor-pointer"
            style="font-size: 20px"
            @click="changeTab(4)"
          >
            EPC
          </h4>
          <h4
            v-if="property.extra_medias && property.extra_medias.length > 0"
            :class="activeTab === 5 ? 'active-tab' : ''"
            class="heading-small px-4 py-3 n-cursor-pointer"
            style="font-size: 20px"
            @click="changeTab(5)"
          >
            Media
          </h4>
        </div>
        <div
          v-show="activeTab === 1"
          style="
            background-color: var(--white);
            border: solid var(--seashell);
            border-width: 0 1px 1px 1px;
          "
          class="px-5 py-5"
        >
          <h4 v-if="property.description" class="heading-small mb-4">
            Description
          </h4>
          <div v-if="property.description">
            <div v-html="property.description" style="white-space: pre-wrap"></div>
          </div>
          <div class="tw-mt-10">
            <library-properties-property-information :property="property" custom-criteria-title-style="letter-spacing: 5px; color: var(--brown--secondary); text-transform: uppercase; font-weight: 300 !important"/>
          </div>
        </div>
        <div
          v-show="activeTab === 2"
          style="
            background-color: var(--white);
            border: solid var(--seashell);
            border-width: 0 1px 1px 1px;
          "
          class="px-5 py-5"
        >
          <neuron-google-map
            v-if="
              mapCenterPosition &&
              mapCenterPosition.lat &&
              mapCenterPosition.lng
            "
            v-model:map-center-position="mapCenterPosition"
            :component="component"
            :marker-color="markerColor"
          />
        </div>
        <div
          v-if="property.floor_plans && property.floor_plans.length > 0"
          v-show="activeTab === 3"
          style="
            background-color: var(--white);
            border: solid var(--seashell);
            border-width: 0 1px 1px 1px;
          "
          class="px-5 py-5"
        >
          <floor-plan :data="property.floor_plans" :component="component" />
        </div>
        <div
          v-if="property.epcs && property.epcs.length > 0"
          v-show="activeTab === 4"
          style="
            background-color: var(--white);
            border: solid var(--seashell);
            border-width: 0 1px 1px 1px;
          "
          class="px-5 py-5"
        >
          <epc :data="property.epcs" :component="component" />
        </div>
        <div
          v-if="property.extra_medias && property.extra_medias.length > 0"
          v-show="activeTab === 5"
          style="
            background-color: var(--white);
            border: solid var(--seashell);
            border-width: 0 1px 1px 1px;
          "
          class="px-5 py-5"
        >
          <extra-media :data="property.extra_medias" :component="component" />
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import NeuronGoogleMap from '~/components/common/NeuronGoogleMap.vue';
import FloorPlan from '~/components/common/FloorPlan.vue';
import Epc from '~/components/common/Epc.vue';
import ExtraMedia from '~/components/common/ExtraMedia.vue';
import { ComponentMixin } from '~/mixins/component.mixin';
import type { PropertyItemComponent } from '~/units/properties/types';

export default defineNuxtComponent({
  name: 'PropertyDetailTabs',

  mixins: [ComponentMixin],

  components: {
    ExtraMedia,
    FloorPlan,
    NeuronGoogleMap,
    Epc,
  },

  props: {
    property: {
      required: true,
      type: Object as PropType<PropertyItemComponent>,
    },

    markerColor: {
      required: true,
    },
  },

  data() {
    return {
      activeTab: 1,
    };
  },

  computed: {
    mapCenterPosition() {
      if (this.property && this.property.property_position) {
        return {
          lat: this.property.property_position.lat,
          lng: this.property.property_position.long,
        };
      }
      return null;
    },
  },

  methods: {
    changeTab(newActiveTab: number) {
      this.activeTab = newActiveTab;
    },
  },
});
</script>

<style scoped>
.active-tab {
  background-color: var(--seashell);
}
</style>
