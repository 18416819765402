<template>
  <section
    class="section-services-content pt-5"
    style="background-color: var(--white)"
  >
    <div class="page-padding pt-5">
      <div class="container-large">
        <div class="padding-bottom padding-huge pt-5">
          <div class="max-width">
            <h1 class="heading-medium">
              {{ vars.titleText }}
            </h1>
          </div>
          <div
            class="why-box d-flex justify-content-between align-items-center"
            style="gap: 10rem"
          >
            <div class="w-100">
              <div class="margin-top margin-small">
                <div class="max-width-full">
                  <p
                    v-for="(item, index) in description"
                    :key="index"
                    class="text-size-tiny text-weight-light mb-4"
                  >
                    {{ item.paragraph }}
                  </p>
                </div>
              </div>
              <div class="margin-top margin-medium">
                <div>
                  <nuxt-link
                    data-w-id="bd454024-605c-912a-bc49-318b72174c6f"
                    :external="true"
                    style="
                      width: auto !important;
                      display: inline-block !important;
                    "
                    :to="vars.buttonUrl"
                    class="button w-inline-block"
                  >
                    <div class="button-label text-weight-light">
                      {{ vars.buttonText }}
                    </div>
                    <div style="height: 0%" class="overlay"></div>
                  </nuxt-link>
                </div>
              </div>
            </div>
            <div class="w-100 image-wrapper">
              <video-popup
                :component="component"
                :video-source="vars.videoSrcText"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import VideoPopup from './VideoPopup.vue';
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'JoinUsIntro',

  mixins: [ComponentMixin],

  components: { VideoPopup },

  data() {
    return {
      swiper: {},
      description: [] as { [key: string]: string | number | null }[],
    };
  },

  created() {
    this.description = this.groupedVariables.description;
  },
});
</script>

<style scoped>
::v-deep(.video-cover iframe) {
  height: 400px;
}
@media screen and (min-width: 991px) {
  .max-width {
    width: 50%;
    font-size: 3.2rem;
  }
  .heading-medium {
    font-size: 3.7rem;
    font-weight: 200;
  }
}
.margin-medium {
  margin-bottom: 0;
}
@media screen and (max-width: 991px) {
  .why-box {
    flex-wrap: wrap;
    gap: 1rem !important;
  }
  .image-wrapper {
    grid-area: 1 / 1 / 2 / 4 !important;
  }
}
.text-size-tiny {
  font-size: 1rem;
}
</style>
