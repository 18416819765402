<template>
  <section
    class="section-services-content"
    :style="`background-color: var(--dark-brown)`"
  >
    <div
      v-if="isSmall"
      class="tw-flex tw-flex-col tw-justify-center tw-p-4 tw-py-10 tw-w-full tw-gap-y-3"
    >
      <h2
        style="color: var(--white)"
        class="tw-text-4xl tw-text-center"
        v-html="vars.titleHtml"
      ></h2>
      <div>
        <p
          class="tw-text-center tw-font-extralight tw-leading-5 tw-px-5"
          style="color: var(--white)"
        >
          {{ vars.descriptionText }}
        </p>
      </div>
      <div class="tw-px-2 tw-flex tw-flex-row tw-gap-x-3">
        <nuxt-link
          v-if="vars.leftButtonUrl"
          id="w-node-aba3c3ea-3ff0-3543-f79c-42814ece1460-89606db2"
          :external="true"
          :to="vars.leftButtonUrl"
          class="button secondary w-inline-block"
          style="background-color: var(--white); color: var(--dark-brown)"
        >
          <div class="button-label tw-text-[8px]">
            {{ vars.leftButtonText }}
          </div>
          <div style="height: 0%" class="overlay"></div>
        </nuxt-link>

        <nuxt-link
          v-if="vars.rightButtonUrl"
          id="w-node-aba3c3ea-3ff0-3543-f79c-42814ece1460-89606db2"
          :external="true"
          :to="vars.rightButtonUrl"
          class="button secondary w-inline-block"
          style="background-color: var(--white); color: var(--dark-brown)"
        >
          <div class="button-label tw-text-[8px]">
            {{ vars.rightButtonText }}
          </div>
          <div style="height: 0%" class="overlay"></div>
        </nuxt-link>
      </div>
    </div>
    <div v-else class="page-padding tw-pt-12 lg:tw-py-12">
      <div class="container-large lg:pt-12">
        <div class="padding-bottom padding-huge">
          <div
            class="why-box d-flex justify-content-between align-items-center"
            style="gap: 5rem"
          >
            <div class="w-100 image-wrapper">
              <h2
                style="color: var(--white)"
                class="heading-medium"
                v-html="vars.titleHtml"
              ></h2>
            </div>
            <div class="w-100">
              <div class="margin-top margin-small">
                <div class="max-width-full">
                  <p
                    class="text-size-tiny text-weight-light text-left"
                    style="color: var(--white)"
                  >
                    {{ vars.descriptionText }}
                  </p>
                </div>
              </div>
              <div class="margin-top margin-medium">
                <div class="d-flex flex-row mt-3 justify-content-left">
                  <nuxt-link
                    id="w-node-aba3c3ea-3ff0-3543-f79c-42814ece1460-89606db2"
                    :external="true"
                    :to="vars.leftButtonUrl"
                    class="button secondary w-inline-block me-3"
                    style="
                      background-color: var(--white);
                      color: var(--dark-brown);
                    "
                  >
                    <div class="button-label">
                      {{ vars.leftButtonText }}
                    </div>
                    <div style="height: 0%" class="overlay"></div>
                  </nuxt-link>
                  <nuxt-link
                    id="w-node-aba3c3ea-3ff0-3543-f79c-42814ece1460-89606db2"
                    :external="true"
                    :to="vars.rightButtonUrl"
                    class="button secondary w-inline-block ms-2"
                    style="
                      background-color: var(--white);
                      color: var(--dark-brown);
                    "
                  >
                    <div class="button-label">
                      {{ vars.rightButtonText }}
                    </div>
                    <div style="height: 0%" class="overlay"></div>
                  </nuxt-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'HeadsUpAlertCTA',

  mixins: [ComponentMixin],

  data() {
    return {};
  },

  computed: {
    isSmall() {
      return this.vars.isSmallNumber;
    },
  },

  methods: {},
});
</script>

<style scoped>
@media screen and (max-width: 991px) {
  .why-box {
    flex-wrap: wrap;
    gap: 1rem !important;
  }
  .image-wrapper {
    grid-area: 1 / 1 / 2 / 4 !important;
  }
}
</style>
