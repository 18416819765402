<template>
  <section
    class="section-services-content pt-5"
    :style="`background-color: var(--white)`"
  >
    <div class="page-padding pt-5">
      <div class="container-large pt-5">
        <div>
          <h1
            class="heading-large office-detail-title mb-0"
            style="
              color: var(--dark-brown);
              font-weight: 200;
              line-height: unset !important;
            "
          >
            {{ vars.titleText }}
          </h1>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
export default defineNuxtComponent({
  name: 'PageIntro',

  mixins: [ComponentMixin],

  data() {
    return {};
  },
});
</script>
