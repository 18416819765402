<template>
  <header
    v-if="isVisible"
    class="section-blog-header"
    style="max-width: 100%"
    :style="`background-color: var(--${backgroundColor}) !important`"
  >
    <div class="page-padding">
      <div class="container-large">
        <div
          class="padding-vertical padding-xhuge"
          style="padding-top: 10px; padding-bottom: 10px"
        >
          <div class="margin-top margin-huge">
            <div class="md:tw-grid md:tw-grid-cols-12 md:tw-gap-x-6">
              <div class="md:tw-col-span-8 tw-mb-4 md:tw-mb-0">
                <nuxt-link
                  v-if="firstBlog"
                  data-w-id="f5198688-baf0-dae4-22dd-e42c6e60a76a"
                  :to="firstBlog.url"
                  :external="true"
                  class="link-content-block featured-blog w-inline-block tw-h-full"
                >
                  <div
                    class="margin-bottom margin-medium tw-h-full tw-z-10 image-wrapper tw-w-full"
                  >
                    <NuxtImg
                      alt=""
                      loading="lazy"
                      style="
                        -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1)
                          rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                        -moz-transform: translate3d(0, 0, 0) scale3d(1, 1, 1)
                          rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                        -ms-transform: translate3d(0, 0, 0) scale3d(1, 1, 1)
                          rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                        transform: translate3d(0, 0, 0) scale3d(1, 1, 1)
                          rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                      "
                      :src="firstBlog.image"
                      class="cover-image"
                      quality="60"
                    />
                  </div>
                  <div class="margin-top margin-small tw-max-w-full">
                    <h2 style="opacity: 1" class="heading-small">
                      {{ firstBlog.title }}
                    </h2>
                    <div class="">
                      <p class="text-color-brown">
                        {{ firstBlog.description }}
                      </p>
                    </div>
                  </div>
                </nuxt-link>
              </div>
              <div class="inner-container md:tw-col-span-4">
                <div>
                  <div role="list" class="tw-flex tw-flex-col tw-gap-y-5">
                    <div v-if="secondBlog" role="listitem" class="w-dyn-item">
                      <nuxt-link
                        :external="true"
                        data-w-id="f5198688-baf0-dae4-22dd-e42c6e60a76a"
                        :to="secondBlog.url"
                        class="tw-flex tw-flex-col"
                      >
                        <div class="margin-bottom margin-medium">
                          <div class="image-wrapper">
                            <NuxtImg
                              alt=""
                              loading="lazy"
                              style="
                                -webkit-transform: translate3d(0, 0, 0)
                                  scale3d(1, 1, 1) rotateX(0) rotateY(0)
                                  rotateZ(0) skew(0, 0);
                                -moz-transform: translate3d(0, 0, 0)
                                  scale3d(1, 1, 1) rotateX(0) rotateY(0)
                                  rotateZ(0) skew(0, 0);
                                -ms-transform: translate3d(0, 0, 0)
                                  scale3d(1, 1, 1) rotateX(0) rotateY(0)
                                  rotateZ(0) skew(0, 0);
                                transform: translate3d(0, 0, 0) scale3d(1, 1, 1)
                                  rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                              "
                              quality="60"
                              :src="secondBlog.image"
                              class="cover-image"
                            />
                          </div>
                        </div>
                        <div class="blog-content">
                          <div class="margin-bottom margin-small">
                            <h3 style="opacity: 1" class="heading-xsmall">
                              {{ secondBlog.title }}
                            </h3>
                          </div>
                          <p class="text-size-small">
                            {{ secondBlog.description }}
                          </p>
                        </div>
                      </nuxt-link>
                    </div>
                    <div v-if="thirdBlog" role="listitem" class="w-dyn-item">
                      <nuxt-link
                        :external="true"
                        data-w-id="f5198688-baf0-dae4-22dd-e42c6e60a76a"
                        :to="thirdBlog.url"
                        class="tw-flex tw-flex-col"
                      >
                        <div class="margin-bottom margin-medium">
                          <div class="image-wrapper">
                            <NuxtImg
                              alt=""
                              loading="lazy"
                              style="
                                -webkit-transform: translate3d(0, 0, 0)
                                  scale3d(1, 1, 1) rotateX(0) rotateY(0)
                                  rotateZ(0) skew(0, 0);
                                -moz-transform: translate3d(0, 0, 0)
                                  scale3d(1, 1, 1) rotateX(0) rotateY(0)
                                  rotateZ(0) skew(0, 0);
                                -ms-transform: translate3d(0, 0, 0)
                                  scale3d(1, 1, 1) rotateX(0) rotateY(0)
                                  rotateZ(0) skew(0, 0);
                                transform: translate3d(0, 0, 0) scale3d(1, 1, 1)
                                  rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                              "
                              quality="60"
                              :src="thirdBlog.image"
                              class="cover-image"
                            />
                          </div>
                        </div>
                        <div class="blog-content">
                          <div class="margin-bottom margin-small">
                            <h3 style="opacity: 1" class="heading-xsmall">
                              {{ thirdBlog.title }}
                            </h3>
                          </div>
                          <p class="text-size-small">
                            {{ thirdBlog.description }}
                          </p>
                        </div>
                      </nuxt-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import type { Component } from '~/types';

export default defineNuxtComponent({
  name: 'SelectedBlogsBox',

  props: {
    component: {
      required: true,
      type: Object as PropType<Component>,
    },
  },

  computed: {
    isVisible() {
      const route = useRoute();
      return !route.query.q && !route.query.page;
    },

    firstBlog() {
      return {
        title: getVariable(this.component, 'first_blog_title-text'),
        description: getVariable(this.component, 'first_blog_description-text'),
        image: getVariable(this.component, 'first_blog-image') as string,
        url: getVariable(this.component, 'first_blog-url') as string,
      };
    },

    secondBlog() {
      return {
        title: getVariable(this.component, 'second_blog_title-text'),
        description: getVariable(
          this.component,
          'second_blog_description-text',
        ),
        image: getVariable(this.component, 'second_blog-image') as string,
        url: getVariable(this.component, 'second_blog-url') as string,
      };
    },

    thirdBlog() {
      return {
        title: getVariable(this.component, 'third_blog_title-text'),
        description: getVariable(this.component, 'third_blog_description-text'),
        image: getVariable(this.component, 'third_blog-image') as string,
        url: getVariable(this.component, 'third_blog-url') as string,
      };
    },

    backgroundColor() {
      return getVariable(this.component, 'background-color');
    },
  },
});
</script>
<style scoped></style>
