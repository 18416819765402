<template>
  <section
    class="section-services-content"
    :class="isSmall ? 'tw-pt-0' : 'pt-5'"
    :style="`background-color: var(--white)`"
  >
    <div class="" :class="isSmall ? 'tw-pt-0' : 'pt-5 page-padding'">
      <div class="container-large" :class="isSmall ? 'tw-pt-0' : 'pt-5'">
        <div
          class="padding-huge"
          :class="isSmall ? 'tw-p-0' : 'pt-5 padding-bottom'"
        >
          <div class="d-flex flex-row justify-content-center w-100">
            <h2 v-if="!isSmall" class="heading-medium">{{ vars.titleText }}</h2>
            <h2 v-if="isSmall" class="heading-medium tw-text-4xl tw-mb-8">
              {{ vars.titleText }}
            </h2>
          </div>
          <Swiper
            :modules="[SwiperNavigation]"
            :slides-per-view="3"
            :space-between="30"
            effect="fade"
            :navigation="{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }"
            :breakpoints="breakpoints"
            class="slider"
            @swiper="onSwiper"
          >
            <SwiperSlide
              v-for="(property, index) in properties"
              :key="`property-${index}`"
              class="d-flex flex-column justify-content-center align-items-center"
            >
              <div
                class="tw-flex tw-flex-col tw-justify-between"
                :class="
                  isSmall ? '' : 'margin-top margin-huge tw-px-4 md:tw-px-0'
                "
              >
                <div
                  data-w-id="24fae542-f661-fbe8-51e2-b81661c752d3"
                  class="tw-px-4"
                >
                  <NuxtImg
                    :src="property.image"
                    quality="60"
                    loading="lazy"
                    class="mb-3 tw-w-full lg:tw-w-[400px] lg:tw-h-[300px] tw-object-fill"
                  />
                </div>
                <property-item :property="property" class="tw-gap-y-2" />
              </div>
            </SwiperSlide>
            <div class="controls" :class="isSmall ? '' : 'd-xl-none'">
              <div
                data-w-id="080a9ca2-7a2d-2ca3-3b22-f42b27569daf"
                class="slide-left-arrow w-slider-arrow-left"
                @click="() => swiper.slidePrev()"
              >
                <NuxtImg
                  src="/cdn/theme2/images/left-arrow.svg"
                  loading="lazy"
                  alt=""
                  quality="60"
                  class="arrow-icon slider-arrow-transform"
                />
                <div class="slide-circle slider-circle-transform"></div>
              </div>
              <div
                data-w-id="080a9ca2-7a2d-2ca3-3b22-f42b27569db1"
                class="slide-right-arrow w-slider-arrow-right"
                @click="() => swiper.slideNext()"
              >
                <NuxtImg
                  src="/cdn/theme2/images/right-arrow.svg"
                  loading="lazy"
                  alt=""
                  quality="60"
                  class="arrow-icon slider-arrow-transform"
                />
                <div class="slide-circle right"></div>
              </div>
              <div class="slide-nav w-slider-nav w-round"></div>
            </div>
          </Swiper>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { PropertiesMixin } from '~/units/properties/mixins/properties.mixin';
import { ComponentMixin } from '~/mixins/component.mixin';
import PropertyItem from '~/components/theme2/components/PropertyItem.vue';

export default defineNuxtComponent({
  name: 'Theme2PropertiesGridBox',
  components: { PropertyItem },

  mixins: [PropertiesMixin, ComponentMixin],

  data() {
    return {
      swiper: {},
    };
  },

  async setup(props) {
    await fetchPropertiesManager(props.component, null);
  },

  computed: {
    isSmall() {
      return this.vars.isSmallNumber;
    },

    breakpoints() {
      if (this.isSmall) {
        return {
          320: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
        };
      }
      return {
        320: {
          slidesPerView: 1,
          spaceBetween: 5,
        },
        1024: {
          slidesPerView: 2,
          spaceBetween: 64,
        },
        1080: {
          slidesPerView: 2,
          spaceBetween: 64,
        },
        1336: {
          slidesPerView: 3,
          spaceBetween: 10,
        },
      };
    },
  },

  methods: {
    onSwiper(swiper: object) {
      this.swiper = swiper;
    },
  },
});
</script>

<style scoped>
::v-deep(.subtitle) {
  display: none;
}

::v-deep(h3) {
  font-size: 1.5rem;
}

::v-deep(p) {
  font-size: 0.9rem;
}

::v-deep(.text-size-small) {
  font-size: 0.75rem;
}

::v-deep(.max-width-content) {
  max-width: max-width-small;
}

::v-deep(.text-size-regular) {
  width: unset !important;
}

::v-deep(.project-content) {
  min-height: 20rem;
}

@media screen and (max-width: 1080px) {
  ::v-deep(.project-content) {
    min-height: unset;
  }
}

@media screen and (max-width: 991px) {
  ::v-deep(.rooms) {
    display: none !important;
  }
}

.controls {
  height: 80px;
  margin-top: 20px;
  text-align: center;
}

.slide-left-arrow,
.slide-right-arrow {
  bottom: unset;
}
</style>
