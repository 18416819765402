<template>
  <section
    class="section-services-content pt-5"
    :style="`background-color: var(--white)`"
  >
    <div class="page-padding pt-5">
      <div class="container-large pt-5">
        <div class="padding-bottom padding-huge pb-1">
          <div
            class="w-layout-grid service-component-wrapper d-flex flex-column"
            style="grid-template-columns: 1fr 5rem 1fr"
          >
            <div
              class="row g-0 justify-content-between align-items-center mb-4"
            >
              <div class="col-lg-8 col-12">
                <h1 class="page-title">Our Locations</h1>
              </div>
              <div class="col-lg-4 col-12 mt-3 mt-lg-0">
                <search-box
                  ref="searchBox"
                  v-model="keyword"
                  :search-result-list="locations"
                  :search-method="onSearch"
                  placeholder="Search by name or address"
                />
              </div>
            </div>
            <div class="row g-0 gx-md-2 pt-lg-5 w-100" style="row-gap: 30px">
              <template v-if="isLoading">
                <section class="utility-page-wrap" style="height: 60dvh">
                  <div class="utility-page-content">
                    <div class="page-padding">
                      <loader :loader-color="colors.brown" />
                    </div>
                  </div>
                </section>
              </template>
              <template v-else-if="!locations.length">
                <not-found
                  :title="notFound.title"
                  :description="notFound.description"
                />
              </template>
              <template v-else-if="locations.length > 0">
                <div
                  v-for="(location, index) in locations"
                  :key="`location-${index}`"
                  class="col-lg-4 col-md-6 col-12 mt-3 mt-lg-0 d-flex flex-column justify-content-start"
                >
                  <nuxt-link :to="location.link" :external="true">
                    <NuxtImg
                      :src="location.photo"
                      :placeholder="[30, 20]"
                      height="313"
                      quality="60"
                      style="
                        object-position: center;
                        object-fit: cover;
                        min-height: 313px;
                        max-height: 313px;
                      "
                      loading="lazy"
                    />
                  </nuxt-link>
                  <h2 style="" class="mt-3 location-title">
                    {{ location.title }}
                  </h2>
                  <div v-if="location.phone" class="mt-3 tw-h-[78px]">
                    <span
                      class="text-size-tiny text-weight-light office-detail__label"
                      >Phone</span
                    >
                    <p class="mb-0 mt-0">
                      <a
                        :href="`tel:${location.phone}`"
                        class="office-detail__value"
                        >{{ location.phone }}</a
                      >
                    </p>
                    <p v-if="location.phone2" class="mb-0 mt-0">
                      <a
                        :href="`tel:${location.phone2}`"
                        class="office-detail__value"
                        >{{ location.phone2 }}</a
                      >
                    </p>
                  </div>

                  <div v-if="location.email" class="mt-3 mb-1 tw-h-[72px]">
                    <span
                      class="text-size-tiny text-weight-light office-detail__label"
                      >Email</span
                    >
                    <p class="mb-0 mt-0">
                      <a
                        :href="`mailto:${location.email}`"
                        class="office-detail__value"
                        >{{ location.email }}</a
                      >
                    </p>
                    <p v-if="location.email2" class="mb-0 mt-0">
                      <a
                        :href="`mailto:${location.email2}`"
                        class="office-detail__value"
                        >{{ location.email2 }}</a
                      >
                    </p>
                  </div>
                  <div class="d-flex flex-row mt-auto">
                    <nuxt-link
                      data-w-id="be7ec0df-c1d4-01e9-596d-897784db125e"
                      :to="location.link"
                      class="button outline w-inline-block n-cursor-pointer"
                      style="width: 150px"
                      :external="true"
                    >
                      <div>VIEW Microsite</div>
                      <div
                        style="
                          -webkit-transform: translate3d(-102%, 0, 0)
                            scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0)
                            skew(0, 0);
                          -moz-transform: translate3d(-102%, 0, 0)
                            scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0)
                            skew(0, 0);
                          -ms-transform: translate3d(-102%, 0, 0)
                            scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0)
                            skew(0, 0);
                          transform: translate3d(-102%, 0, 0) scale3d(1, 1, 1)
                            rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                        "
                        class="hover-underline"
                      ></div>
                    </nuxt-link>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
import SearchBox from '~/components/common/SearchBox.vue';
import NotFound from '~/components/theme2/base/NotFound.vue';
import Loader from '~/components/common/Loader.vue';
import { sleep } from '~/helpers';

export default defineNuxtComponent({
  name: 'Theme2LocationsList',
  components: { Loader, NotFound, SearchBox },

  mixins: [ComponentMixin],

  data() {
    return {
      keyword: '',
      locations: [],
      isLoading: false,
    };
  },

  created() {
    const route = useRoute();
    if (route.query.q) {
      this.keyword = route.query.q;
    }
    this.onSearch();
  },

  computed: {
    notFound() {
      const keyword = this.keyword;
      return {
        title: keyword ? `No Results for "${keyword}"` : 'No Results',
        description: `Oops! It seems there are no offices with that name or address ${
          keyword ? `for "${keyword}"` : ''
        }. Feel free to try a different keyword. `,
      };
    },
  },

  methods: {
    async onSearch() {
      this.isLoading = true;
      const router = useRouter();

      const keyword = this.keyword.toUpperCase();
      const locations = this.groupedVariables.offices.map(i => {
        return {
          ...i,
          indices: i.indices && i.indices.length > 0 ? `${i.indices}, ${i.title}` : i.title,
        }
      })

      const sortFunc = (a, b) => {
        return a.title.localeCompare(b.title);
      }

      if (keyword) {
        await router.replace({
          query: {
            q: this.keyword,
          },
        });
        await sleep(1000);
        this.locations = locations
          .filter((office) => {
            return (
              !!office &&
              typeof office.indices === 'string' &&
              office.indices.toUpperCase().includes(keyword)
            );
          })
          .sort(sortFunc);
      } else {
        await router.replace({
          query: {
            q: undefined,
          },
        });
        this.locations = locations.sort(sortFunc);
      }

      this.isLoading = false;
    },
  },
});
</script>

<style scoped>
.office-detail__label {
  font-weight: 300;
  color: var(--brown--secondary);
  font-size: 0.7rem;
  line-height: 2px;
  letter-spacing: 5px;
  text-transform: uppercase;
}
.office-detail__value {
  font-weight: 300;
  color: var(--dark-brown);
  font-size: 16px;
  margin: 0;
}

.location-title {
  color: var(--dark-brown);
  font-weight: 300;
  font-size: 22px;
}

.page-title {
  color: var(--dark-brown);
  font-weight: 300;
  font-size: 80px;
  line-height: 70px;
}

a:hover .hover-underline {
  transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg) !important;
}

.hover-underline {
  transition: 500ms all;
}
</style>
