<template>
  <section
    class="section-home-blog"
    :style="`background-color: var(--${backgroundColor}) !important`"
  >
    <div :class="variants.v3 === variant ? '' : 'page-padding'">
      <div class="container-large">
        <div
          :class="
            variants.v3 === variant
              ? 'tw-py-16'
              : variants.v1 === variant
              ? 'tw-py-24'
              : 'padding-vertical padding-xhuge'
          "
          :style="isPaddingMutated ? '' : ''"
        >
          <div
            v-if="isTopBlogContentVisible"
            :class="
              variants.v3 === variant
                ? 'tw-flex tw-flex-col tw-gap-y-6 tw-mb-10'
                : 'top-blog-content'
            "
          >
            <div class="max-width-large">
              <h2
                :class="
                  variants.v3 === variant ? 'tw-text-4xl' : 'heading-medium'
                "
                style="color: var(--dark-brown)"
                v-html="titleHtml"
              ></h2>
            </div>
            <div v-if="variants.v3 === variant" class="tw-grid tw-grid-cols-6">
              <div class="tw-col-span-3">
                <nc-button
                  link="/blogs"
                  :external="true"
                  :is-link="true"
                  label="LATEST BLOGS"
                >
                </nc-button>
              </div>
            </div>
            <div v-else>
              <nc-button
                link="/blogs"
                :external="true"
                :is-link="true"
                label="LATEST BLOGS"
              >
              </nc-button>
            </div>
          </div>
          <div class="articles-list-wrapper w-dyn-list">
            <div v-if="isBlogsLoading">
              <section class="utility-page-wrap" style="height: 60dvh">
                <div class="utility-page-content">
                  <div class="page-padding">
                    <loader :loader-color="colors.brown" />
                  </div>
                </div>
              </section>
            </div>
            <div v-else-if="blogs && blogs.length === 0">
              <not-found
                :title="notFound.title"
                :description="notFound.description"
              />
            </div>
            <div
              v-else-if="!isBlogsLoading"
              role="list"
              :class="
                variant === variants.v3
                  ? 'tw-flex tw-flex-col tw-gap-y-8'
                  : 'blog-posts-component w-dyn-items'
              "
            >
              <div
                v-for="(blog, index) in blogs"
                :key="`blog-${index}`"
                role="listitem"
                class="w-dyn-item"
              >
                <nuxt-link
                  :to="`/blogs/${blog.url}`"
                  data-w-id="f5198688-baf0-dae4-22dd-e42c6e60a76a"
                  class="link-content-block blog w-inline-block"
                  :external="true"
                >
                  <div class="margin-bottom margin-medium">
                    <div class="image-wrapper blog">
                      <img
                        alt=""
                        loading="lazy"
                        style="
                          -webkit-transform: translate3d(0, 0, 0)
                            scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0)
                            skew(0, 0);
                          -moz-transform: translate3d(0, 0, 0) scale3d(1, 1, 1)
                            rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                          -ms-transform: translate3d(0, 0, 0) scale3d(1, 1, 1)
                            rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                          transform: translate3d(0, 0, 0) scale3d(1, 1, 1)
                            rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                        "
                        :src="blog.image"
                        class="cover-image"
                      />
                    </div>
                  </div>
                  <div class="blog-content tw-w-full">
                    <div class="margin-bottom margin-small tw-mb-2 tw-h-14">
                      <h3 style="opacity: 1" class="heading-xsmall">
                        {{ blog.title }}
                      </h3>
                    </div>
                    <p class="text-size-small">
                      {{ blog.description }}
                    </p>
                  </div>
                </nuxt-link>
              </div>
            </div>
            <div
              v-if="isPaginationVisible"
              class="d-flex flex-row justify-content-center align-items-center mt-4"
              style="
                column-gap: 20px;
                font-size: 16px;
                color: var(--dark-brown);
              "
            >
              <div
                v-if="activePage !== 1"
                class="nc-icon-chevron-left n-cursor-pointer"
                style="color: #999999"
                @click="onClickedNavigationArrow(-1)"
              ></div>
              <div
                v-for="(page, index) in pages"
                :key="`pageNumber-${index}`"
                :class="activePage === page.id ? 'selected-page' : ''"
              >
                <div
                  v-if="page.is_clickable"
                  class="n-cursor-pointer"
                  @click="onClickedPage(page)"
                >
                  {{ page.title }}
                </div>
                <div v-else>
                  {{ page.title }}
                </div>
              </div>
              <div
                v-if="activePage !== pageCount"
                class="nc-icon-chevron-right n-cursor-pointer"
                style="color: #999999"
                @click="onClickedNavigationArrow(1)"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import type { Component } from '~/types';
import Loader from '~/components/common/Loader.vue';
import NotFound from '~/components/theme2/base/NotFound.vue';
import indexGlobal from '~/mixins/index.global';
import { BlogsMixin } from '~/units/blogs/mixins/blogs.mixin';
import NcButton from '~/components/common/inputs/NcButton.vue';
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'Theme2BlogsBox',
  components: { NcButton, NotFound, Loader },

  mixins: [BlogsMixin, indexGlobal, ComponentMixin],

  async setup(props: { component: Component }) {
    const route = useRoute();
    const keyword = route.query.q ? route.query.q.toString() : null;

    await fetchBlogsManager(props.component, keyword);
  },

  data() {
    return {
      variants: {
        v1: 'BlogsBox1',
        v2: 'BlogsBox2',
        v3: 'BlogsBox3',
      },
    };
  },

  computed: {
    isTopBlogContentVisible() {
      return !!getVariable(this.component, 'is_top_content_visible-number');
    },

    isPaginationVisible() {
      return !this.isTopBlogContentVisible && !this.isBlogsLoading;
    },

    isPaddingMutated() {
      const variant = useVariant(this.component);
      return variant === this.variants.v1;
    },

    variant() {
      return useVariant(this.component);
    },

    backgroundColor() {
      return getVariable(this.component, 'background-color');
    },

    titleHtml() {
      return this.vars.titleHtml || 'Take a look at our latest blogs';
    },
  },

  watch: {
    async $route(a) {
      await fetchBlogsManager(
        this.component,
        a.query && a.query.q ? a.query.q : null,
      );
    },
  },
});
</script>

<style scoped>
.link-content-block.blog {
  flex-direction: column !important;
}
.image-wrapper.blog {
  width: 100% !important;
}

.selected-page {
  border-bottom: 2px solid var(--brown);
}

.blog-content h3 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
