<template>
  <section
    class="section-services-content"
    :style="`background-color: var(--white)`"
  >
    <div class="page-padding">
      <div class="container-large">
        <div class="padding-bottom padding-huge">
          <div class="d-flex flex-row justify-content-center w-100">
            <h2 class="heading-medium">{{ vars.titleText }}</h2>
          </div>
          <Swiper
            :modules="[SwiperNavigation]"
            :slides-per-view="3"
            :space-between="30"
            effect="fade"
            :navigation="{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }"
            :breakpoints="{
              320: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              1080: {
                slidesPerView: 2,
                spaceBetween: 42,
              },
              1336: {
                slidesPerView: 3,
                spaceBetween: 42,
              },
            }"
            class="slider"
            @swiper="onSwiper"
          >
            <SwiperSlide
              v-for="(faq, index) in faqs"
              :key="`faq-${index}`"
              class="d-flex flex-column justify-content-center align-items-center"
            >
              <div class="margin-top margin-huge">
                <div
                  data-w-id="24fae542-f661-fbe8-51e2-b81661c752d3"
                  class="image-wrapper project max-width-small"
                >
                  <iframe
                    class="video mb-3"
                    width="100%"
                    height="250"
                    :src="faq.video"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </div>
                <h3
                  class="heading-xsmall tw-h-[50px] tw-text-ellipsis tw-overflow-hidden"
                >
                  {{ faq.question }}
                </h3>
                <p class="tw-mt-2">{{ faq.answer }}</p>
              </div>
            </SwiperSlide>
            <div class="controls d-xl-none">
              <div
                data-w-id="080a9ca2-7a2d-2ca3-3b22-f42b27569daf"
                class="slide-left-arrow w-slider-arrow-left"
                @click="() => swiper.slidePrev()"
              >
                <img
                  src="/cdn/theme2/images/left-arrow.svg"
                  loading="lazy"
                  alt="Left Arrow - Inner Webflow Template"
                  class="arrow-icon slider-arrow-transform"
                />
                <div class="slide-circle slider-circle-transform"></div>
              </div>
              <div
                data-w-id="080a9ca2-7a2d-2ca3-3b22-f42b27569db1"
                class="slide-right-arrow w-slider-arrow-right"
                @click="() => swiper.slideNext()"
              >
                <img
                  src="/cdn/theme2/images/right-arrow.svg"
                  loading="lazy"
                  alt="Right Arrow - Inner Webflow Template"
                  class="arrow-icon slider-arrow-transform"
                />
                <div class="slide-circle right"></div>
              </div>
              <div class="slide-nav w-slider-nav w-round"></div>
            </div>
          </Swiper>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'FAQ',

  mixins: [ComponentMixin],

  data() {
    return {
      swiper: {},
      faqs: [] as { [key: string]: string | number | null }[],
    };
  },

  created() {
    this.faqs = this.groupedVariables.faqs;
  },

  methods: {
    onSwiper(swiper: object) {
      this.swiper = swiper;
    },
  },
});
</script>

<style scoped>
.office-detail__label {
  font-weight: 300;
  color: var(--brown--secondary);
  font-size: 0.8rem;
  line-height: 14.35px;
  letter-spacing: 5px;
  text-transform: uppercase;
}
.office-detail__value {
  font-weight: 300;
  color: var(--dark-brown);
  font-size: 1.3rem;
  padding-right: 4rem;
}
.w-min-content {
  width: min-content;
}

.office-detail-title {
  width: min-content;
  font-size: 6.25rem;
}

.social-media {
  background: var(--dark-brown);
  width: 36px;
  height: 36px;
  flex-shrink: 1;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
}

h3 {
  font-size: 1.3rem;
}

@media (max-width: 1350px) {
  .max-width-small {
    max-width: unset;
  }
}
@media screen and (max-width: 991px) {
  .image-wrapper.project {
    width: unset;
  }
}
</style>
