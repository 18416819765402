<template>
  <div class="media-tab">
    <div
      v-for="(media, index) in data"
      :key="`extraMedia-${index}`"
      class="media-card d-flex flex-row justify-content-between"
    >
      <div class="d-flex flex-row align-items-center">
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
        >
          <path :d="icons.pdfIconPart1"></path>
          <path :d="icons.pdfIconPart2"></path>
        </svg>

        <div class="content ms-4 d-flex flex-column">
          <span>
            {{ media.name }}
          </span>
          <a
            :href="media.mediaLink"
            target="_blank"
            class="media-link n-cursor-pointer"
            rel="noopener noreferrer"
          >
            click here to open the file
          </a>
        </div>
      </div>
      <div class="">
        <div
          v-if="media.extension"
          class="text-uppercase text-tag d-flex flex-row align-items-center"
        >
          {{ media.extension }}
        </div>
        <div v-else class="brick text-tag d-flex flex-row align-items-center">
          Other
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ComponentMixin } from '~/mixins/component.mixin';

export default {
  name: 'ExtraMedia',

  mixins: [ComponentMixin],

  props: {
    data: {
      required: true,
      type: Array,
    },
  },
};
</script>

<style scoped>
.text-tag::before {
  background-color: #da3741;
}

.text-tag::before {
  content: '';
  position: relative;
  display: block;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin: 0 0.5rem 0 0;
}

.media-tab h4 {
  color: #000;
  margin: 0 0 1rem;
  font-size: 1.125rem;
  line-height: 129.7%;
  font-weight: 600;
}

.media-tab .media-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 1.5rem;
  background-color: #f8f8f8;
  margin: 1rem 0;
  border-radius: 12px;
}

.media-tab .media-card i {
  color: var(--primary_color);
  font-size: 2.5rem;
  margin: 0 1rem 0 0;
}

.media-tab .media-card .content p {
  color: #000;
  margin: 0 0 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
}

.media-tab .media-card .content a {
  color: var(--primary_color);
  font-size: 0.875rem;
  font-weight: 400;
}

.media-tab .media-card .content a:hover {
  color: var(--secondary_color);
}

.media-tab .media-card .pm-tag {
  margin-left: auto;
}

.pm-tag.text-tag {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 500;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.pm-tag.text-tag::before {
  content: '';
  position: relative;
  display: block;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin: 0 0.5rem 0 0;
}

.pm-tag.text-tag.brick::before {
  background-color: #da3741;
}

@media (max-width: 991px) {
  .media-tab .media-card {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .media-tab .media-card i {
    margin-bottom: 1rem;
  }
  .media-tab .media-card .pm-tag {
    display: none;
  }
}

.media-link {
  text-decoration: underline;
}

.media-link:hover {
  text-decoration: underline;
}
</style>
