<template>
  <section
    class="section-home-blog"
    :class="isSmall ? '' : 'tw-py-24'"
    :style="`background-color: var(--white) !important`"
  >
    <div :class="isSmall ? '' : 'page-padding'">
      <div class="container-large">
        <div
          class=""
          :class="[
            vars.styleHtml,
            isSmall ? '' : 'padding-xhuge padding-vertical',
          ]"
        >
          <div class="tw-flex tw-flex-row tw-justify-center tw-w-full">
            <h2
              :class="
                isSmall
                  ? 'heading-medium tw-text-3xl tw-text-center'
                  : 'heading-medium'
              "
            >
              {{ vars.titleText }}
            </h2>
          </div>
          <div class="tw-mt-10">
            <Swiper
              v-if="guides && guides.length > 0"
              :modules="[SwiperNavigation]"
              :slides-per-view="1"
              :space-between="81"
              effect="fade"
              :wrapper-class="wrapperClass"
              :navigation="{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              }"
              :breakpoints="breakpoints"
              @swiper="onSwiper"
            >
              <SwiperSlide
                v-for="(guide, index) in guides"
                :key="`guide-${index}`"
                role="listitem"
                class="w-dyn-item d-flex flex-column justify-content-center align-items-center"
              >
                <div
                  v-if="guide"
                  data-w-id="f5198688-baf0-dae4-22dd-e42c6e60a76a"
                  class="link-content-block blog w-inline-block justify-content-between"
                >
                  <div class="margin-bottom">
                    <div class="lg:tw-h-[400px] tw-h-fit">
                      <nuxt-link :to="guide.link" :external="true">
                        <NuxtImg
                          alt=""
                          loading="lazy"
                          class="tw-object-contain"
                          style="
                            -webkit-transform: translate3d(0, 0, 0)
                              scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0)
                              skew(0, 0);
                            -moz-transform: translate3d(0, 0, 0)
                              scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0)
                              skew(0, 0);
                            -ms-transform: translate3d(0, 0, 0) scale3d(1, 1, 1)
                              rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                            transform: translate3d(0, 0, 0) scale3d(1, 1, 1)
                              rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                            width: 100%;
                            height: 100%;
                          "
                          quality="60"
                          :src="guide.image"
                        />
                      </nuxt-link>
                    </div>
                    <div class="blog-content mt-3">
                      <div class="margin-bottom margin-small">
                        <h3 style="opacity: 1" class="heading-xsmall">
                          {{ guide.title }}
                        </h3>
                      </div>
                    </div>
                  </div>
                  <nuxt-link
                    :to="guide.link"
                    data-w-id="be7ec0df-c1d4-01e9-596d-897784db125e"
                    class="button outline w-inline-block n-cursor-pointer"
                    style="width: 140px"
                    :external="true"
                  >
                    <div>get this guide</div>
                    <div
                      style="
                        -webkit-transform: translate3d(-102%, 0, 0)
                          scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0)
                          skew(0, 0);
                        -moz-transform: translate3d(-102%, 0, 0)
                          scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0)
                          skew(0, 0);
                        -ms-transform: translate3d(-102%, 0, 0) scale3d(1, 1, 1)
                          rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                        transform: translate3d(-102%, 0, 0) scale3d(1, 1, 1)
                          rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                      "
                      class="hover-underline"
                    ></div>
                  </nuxt-link>
                </div>
              </SwiperSlide>
              <div
                class="controls"
                :class="
                  (guides && guides.length > 4) ||
                  (isSmall && guides && guides.length > 1)
                    ? ''
                    : ' d-xl-none'
                "
              >
                <div
                  data-w-id="080a9ca2-7a2d-2ca3-3b22-f42b27569daf"
                  class="slide-left-arrow w-slider-arrow-left"
                  @click="() => swiper.slidePrev()"
                >
                  <img
                    src="/cdn/theme2/images/left-arrow.svg"
                    loading="lazy"
                    alt="Left Arrow - Inner Webflow Template"
                    class="arrow-icon slider-arrow-transform"
                  />
                  <div class="slide-circle slider-circle-transform"></div>
                </div>
                <div
                  data-w-id="080a9ca2-7a2d-2ca3-3b22-f42b27569db1"
                  class="slide-right-arrow w-slider-arrow-right"
                  @click="() => swiper.slideNext()"
                >
                  <img
                    src="/cdn/theme2/images/right-arrow.svg"
                    loading="lazy"
                    alt="Right Arrow - Inner Webflow Template"
                    class="arrow-icon slider-arrow-transform"
                  />
                  <div class="slide-circle right"></div>
                </div>
                <div class="slide-nav w-slider-nav w-round"></div>
              </div>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'GuidesBoxTheme2',

  mixins: [ComponentMixin],

  data() {
    return {
      swiper: {},
    };
  },

  computed: {
    guides() {
      return this.groupedVariables.guide;
    },

    wrapperClass() {
      return this.guides && this.guides.length < 4
        ? 'swiper-wrapper swiper-wrapper-centered'
        : 'swiper-wrapper';
    },

    isSmall() {
      return this.vars.isSmallNumber;
    },

    breakpoints() {
      if (this.isSmall) {
        return {
          320: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
        };
      }
      return {
        320: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        560: {
          slidesPerView: 2,
          spaceBetween: 24,
        },
        1080: {
          slidesPerView: 3,
          spaceBetween: 64,
        },
        1336: {
          slidesPerView: 4,
          spaceBetween: 72,
        },
      };
    },
  },

  methods: {
    onSwiper(swiper: object) {
      this.swiper = swiper;
    },
  },
});
</script>

<style scoped>
.blog-content {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.swiper-slide {
  height: unset !important;
}

.controls {
  height: 80px;
  margin-top: 20px;
  text-align: center;
}

.slide-left-arrow,
.slide-right-arrow {
  bottom: unset;
}

.link-content-block.blog {
  flex-direction: column !important;
  align-items: flex-start !important;
}

::v-deep(.swiper-wrapper-centered) {
  display: flex !important;
  justify-content: center !important;
}

@media screen and (max-width: 991px) {
  ::v-deep(.swiper-wrapper-centered) {
    justify-content: left !important;
  }
}
</style>
