<template>
  <section
    class="section-services-content pt-5"
    :style="`background-color: white`"
  >
    <div class="page-padding">
      <div class="container-large">
        <div class="padding-bottom tw-pb-1">
          <div class="d-flex flex-row justify-content-center w-100">
            <h2 class="heading-medium">{{ vars.titleText }}</h2>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'OurOptions',

  mixins: [ComponentMixin],
});
</script>
