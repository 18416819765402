<template>
  <section class="section-home-projects">
    <div class="page-padding">
      <div class="container-large">
        <div class="padding-vertical tw-pt-12 lg:tw-pt-20 tw-pb-1">
          <h2
            data-w-id="f24a8106-5fde-9f40-5a81-741c40501bbb"
            style="
              -webkit-transform: translate3d(0, 100%, 0) scale3d(1, 1, 1)
                rotateX(-90deg) rotateY(0) rotateZ(0) skew(0, 0);
              -moz-transform: translate3d(0, 100%, 0) scale3d(1, 1, 1)
                rotateX(-90deg) rotateY(0) rotateZ(0) skew(0, 0);
              -ms-transform: translate3d(0, 100%, 0) scale3d(1, 1, 1)
                rotateX(-90deg) rotateY(0) rotateZ(0) skew(0, 0);
              transform: translate3d(0, 100%, 0) scale3d(1, 1, 1)
                rotateX(-90deg) rotateY(0) rotateZ(0) skew(0, 0);
              transform-style: preserve-3d;
            "
            class="heading-medium"
          >
            {{ vars.titleText }}
          </h2>
          <div class="row mt-5">
            <div class="col-xl-3">
              <nc-button
                :external="true"
                link="/properties"
                :is-link="true"
                label="VIEW ALL PROPERTIES"
              >
              </nc-button>
            </div>
          </div>
          <template
            v-for="(property, index) in properties"
            :key="`property-${index}`"
          >
            <div v-if="index % 2 === 0" class="margin-top margin-huge">
              <div
                data-w-id="c6a88721-dc2e-8885-8171-5c2a2cf0c30e"
                role="listitem"
                class="project-component-item w-dyn-item"
                style="grid-template-columns: 2fr 0.4fr 4fr !important"
              >
                <nuxt-link
                  id="w-node-_2bcc056a-8efc-6913-1c7c-8c7a5162fa69-89606d42"
                  class="link-content-project w-inline-block"
                  :external="true"
                  :to="property.detail_page_url"
                >
                  <div
                    data-w-id="a54b95fc-1a02-3ec1-e327-af6847268937"
                    class="image-wrapper project n-cursor-pointer"
                  >
                    <img
                      :src="property.image"
                      loading="lazy"
                      alt=""
                      class="cover-image project"
                      style="
                        display: block;
                        -webkit-transform: translate3d(100%, 0, 0)
                          scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0)
                          skew(0, 0);
                        -moz-transform: translate3d(100%, 0, 0) scale3d(1, 1, 1)
                          rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                        -ms-transform: translate3d(100%, 0, 0) scale3d(1, 1, 1)
                          rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                        transform: translate3d(100%, 0, 0) scale3d(1, 1, 1)
                          rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                      "
                    />
                    <div
                      style="
                        display: block;
                        background: var(--white);
                        -webkit-transform: translate3d(100%, 0, 0)
                          scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0)
                          skew(0, 0);
                        -moz-transform: translate3d(100%, 0, 0) scale3d(1, 1, 1)
                          rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                        -ms-transform: translate3d(100%, 0, 0) scale3d(1, 1, 1)
                          rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                        transform: translate3d(100%, 0, 0) scale3d(1, 1, 1)
                          rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                      "
                      class="overlay-animation"
                    ></div>
                  </div>
                </nuxt-link>
                <div
                  id="w-node-bd24ad7c-b3e8-360f-777a-4284a263237a-89606d42"
                  class="inner-container"
                >
                  <property-item :property="property" />
                </div>
              </div>
            </div>
            <div v-else class="margin-top margin-xxhuge tw-my-20">
              <div
                data-w-id="9d560480-e7fd-2f54-cdb4-fc48086e8c03"
                role="listitem"
                class="project-component-item w-dyn-item"
                style="grid-template-columns: 4fr 0.4fr 2fr !important"
              >
                <nuxt-link
                  id="w-node-b6c0cbc3-7937-282a-0b74-6d2fb469dddd-89606d42"
                  :external="true"
                  :to="property.detail_page_url"
                  class="link-content-project align-flex-right-tablet w-inline-block"
                >
                  <div
                    data-w-id="24fae542-f661-fbe8-51e2-b81661c752d3"
                    class="image-wrapper project"
                  >
                    <img
                      :src="property.image"
                      loading="lazy"
                      alt=""
                      class="cover-image project"
                    />
                    <div
                      style="
                        display: block;
                        background: var(--white);
                        -webkit-transform: translate3d(100%, 0, 0)
                          scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0)
                          skew(0, 0);
                        -moz-transform: translate3d(100%, 0, 0) scale3d(1, 1, 1)
                          rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                        -ms-transform: translate3d(100%, 0, 0) scale3d(1, 1, 1)
                          rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                        transform: translate3d(100%, 0, 0) scale3d(1, 1, 1)
                          rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                      "
                      class="overlay-animation"
                    ></div>
                  </div>
                  <div
                    data-w-id="ef3165f8-ad3d-53af-f8c0-376f36a7f841"
                    class="second-image-project left"
                  >
                    <div
                      style="
                        display: block;
                        -webkit-transform: translate3d(100%, 0, 0)
                          scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0)
                          skew(0, 0);
                        -moz-transform: translate3d(100%, 0, 0) scale3d(1, 1, 1)
                          rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                        -ms-transform: translate3d(100%, 0, 0) scale3d(1, 1, 1)
                          rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                        transform: translate3d(100%, 0, 0) scale3d(1, 1, 1)
                          rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                      "
                      class="overlay-animation"
                    ></div>
                  </div>
                </nuxt-link>
                <div
                  id="w-node-be7ec0df-c1d4-01e9-596d-897784db1257-89606d42"
                  class="inner-container padding-left padding-medium pl-0"
                >
                  <property-item :property="property" />
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { PropertiesMixin } from '~/units/properties/mixins/properties.mixin';
import { ComponentMixin } from '~/mixins/component.mixin';
import PropertyItem from '~/components/theme2/components/PropertyItem.vue';
import NcButton from '~/components/common/inputs/NcButton.vue';

export default defineNuxtComponent({
  name: 'Theme2PropertiesBox',
  components: { NcButton, PropertyItem },

  mixins: [PropertiesMixin, ComponentMixin],

  async setup(props) {
    await fetchPropertiesManager(props.component, null);
  },
});
</script>

<style scoped>
.image-wrapper.project {
  width: 100% !important;
}
</style>
