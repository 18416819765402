<template>
  <div class="nc-text tw-pt-12 md:tw-pt-0" style="background-color: var(--white)">
    <section
      v-if="property"
      class="section-product-content pb-5"
      style="background-color: var(--white)"
    >
      <div class="page-padding">
        <div class="container-large">
          <div class="padding-top padding-xhuge">
            <div class="row g-0">
              <div
                id="w-node-a1202a3f-2ddc-9dfb-6534-95f035b2fc36-89606d9b"
                class="col-lg-6"
              >
                <div
                  v-if="firstImage"
                  class="image-wrapper image-container n-cursor-pointer"
                >
                  <library-image :src="firstImage?.background" @click="onClickedImage(firstImage)">
                  </library-image>
                  <div class="first-image-overlay-item">
                    <div
                      v-if="medias"
                      style="
                        background-color: var(--white);
                        color: var(--dark-brown);
                      "
                      class="px-2 py-1"
                    >
                      1/{{ medias.length }}
                    </div>
                  </div>
                </div>
                <div class="row g-0 pt-1">
                  <div v-if="secondImage" class="col-6 pe-1 n-cursor-pointer">
                    <library-image :src="secondImage?.background" @click="onClickedImage(secondImage)">
                    </library-image>
                  </div>
                  <div v-if="thirdImage" class="col-6 ps-1 n-cursor-pointer">
                    <library-image :src="thirdImage?.background" @click="onClickedImage(thirdImage)">
                    </library-image>
                  </div>
                </div>
              </div>
              <div
                id="w-node-d0e446c1-f65a-c0b2-1cff-f23737d9485d-89606d9b"
                class="col-lg-6 ps-lg-5 pt-4 pt-lg-0"
              >
                <div class="margin-bottom margin-medium">
                  <div class="d-flex flex-row justify-content-between mb-3">
                    <div>
                      <div
                        v-if="property.custom_property_status_title"
                        class="property-image-badge"
                      >
                        {{ property.custom_property_status_title }}
                      </div>
                    </div>
                    <div>
                      {{ property.online_date_from_now }}
                    </div>
                  </div>
                  <h1 class="heading-small">
                    {{ property.advert_heading }}
                  </h1>
                  <h2 class="heading-xsmall mt-2 text-capitalize">
                    {{ property.display_address }}
                  </h2>
                  <div v-if="property.price" class="mt-4">
                    <div class="text-size-small neuron-capitalise">
                      {{ property.price.type.name }}
                    </div>
                    <div class="price">
                      {{ property.price.price }}
                    </div>
                  </div>
                  <div class="d-flex flex-row justify-content-between mt-5">
                    <div v-if="property.bedroom" class="d-flex flex-column">
                      <div class="property-criteria__label">BEDROOM</div>
                      <div class="property-criteria__value">
                        {{ property.bedroom }}
                      </div>
                    </div>
                    <div v-if="property.bathroom" class="d-flex flex-column">
                      <div class="property-criteria__label">BATHROOM</div>
                      <div class="property-criteria__value">
                        {{ property.bathroom }}
                      </div>
                    </div>
                    <div v-if="property.reception" class="d-flex flex-column">
                      <div class="property-criteria__label">RECEPTION</div>
                      <div class="property-criteria__value">
                        {{ property.reception }}
                      </div>
                    </div>
                  </div>
                  <div
                    class="tw-grid tw-grid-cols-8 tw-mt-8 xl:tw-gap-x-6 tw-gap-y-5 xl:tw-gap-y-0"
                  >
                    <div class="xl:tw-col-span-3 tw-col-span-8">
                      <nc-button
                        label="ARRANGE A VIEWING"
                        :height="55"
                        @on-click="onSendLead()"
                      ></nc-button>
                    </div>
                    <div class="xl:tw-col-span-4 tw-col-span-8">
                      <nuxt-link
                        to="/valuation"
                        data-w-id="be7ec0df-c1d4-01e9-596d-897784db125e"
                        :external="true"
                        class="button outline w-inline-block"
                        style="color: var(--brown)"
                      >
                        <div>What’s MY property worth?</div>
                        <div
                          style="
                            -webkit-transform: translate3d(-102%, 0, 0)
                              scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0)
                              skew(0, 0);
                            -moz-transform: translate3d(-102%, 0, 0)
                              scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0)
                              skew(0, 0);
                            -ms-transform: translate3d(-102%, 0, 0)
                              scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0)
                              skew(0, 0);
                            transform: translate3d(-102%, 0, 0) scale3d(1, 1, 1)
                              rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                          "
                          class="hover-underline"
                        ></div>
                      </nuxt-link>
                    </div>
                  </div>

                  <div
                    class="tw-grid tw-grid-cols-8 tw-mt-8 lg:tw-gap-x-6 tw-gap-y-5 lg:tw-gap-y-0"
                  >
                    <div
                      v-if="property.branch_phone_number"
                      class="tw-flex tw-flex-col lg:tw-col-span-3 tw-col-span-8"
                    >
                      <div style="letter-spacing: 5px" class="text-size-tiny">
                        CALL NOW
                      </div>
                      <a
                        :href="`tel:${property.branch_phone_number}`"
                        class="tw-text-base tw-font-extralight"
                      >
                        {{ property.branch_phone_number }}
                      </a>
                    </div>
                    <div
                      v-if="property.branch_email"
                      class="tw-flex tw-flex-col lg:tw-col-span-4 tw-col-span-8"
                    >
                      <div style="letter-spacing: 5px" class="text-size-tiny">
                        EMAIL
                      </div>
                      <a
                        :href="`mailto:${property.branch_email}`"
                        class="tw-text-base tw-font-extralight"
                      >
                        {{ property.branch_email }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      v-if="property"
      class="section-product-content pb-5"
      style="background-color: var(--white)"
    >
      <div class="page-padding">
        <div class="container-large">
          <div class="row g-0 pt-5">
            <div
              v-if="property"
              class="tw-grid tw-grid-cols-12"
              :class="property?.featured_video ? 'col-lg-6 col-12' : 'col-12'"
            >
              <div
                v-for="(feature, key) in property.features"
                :key="`property-feature-${key}`"
                class="text-weight-light mb-5 d-flex flex-row align-items-start pe-4"
                style="color: var(--brown--secondary); font-size: 16px"
                :class="
                  property?.featured_video
                    ? 'tw-col-span-12 lg:tw-col-span-6'
                    : 'tw-col-span-12 lg:tw-col-span-4'
                "
              >
                <div class="me-4">
                  <svg
                    width="22"
                    height="19"
                    viewBox="0 0 22 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path :d="icons.check" stroke="#AF624E" stroke-width="3" />
                  </svg>
                </div>
                <div>
                  {{ feature }}
                </div>
              </div>
            </div>
            <div
              v-if="property?.featured_video"
              class="col-lg-6 col-12 d-flex justify-content-end n-cursor-pointer"
            >
              <lazy-theme2-components-video-popup
                :component="component"
                :video-source="property.featured_video.mediaLink"
              />
              <div v-if="false" class="image-container">
                <div class="overlay-item">
                  <svg
                    width="100"
                    height="100"
                    viewBox="0 0 100 100"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path :d="icons.bigPlayButton" fill="white" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <property-detail-tabs
      v-if="property"
      :property="property"
      :component="component"
      :marker-color="markerColor"
    />
    <full-screen-gallery
      v-if="fullScreenPopupConfig.is_active && activeMedia && medias"
      v-model:active-media-index="activeMediaIndex"
      :component="component"
      :active-media="activeMedia"
      :count-of-medias="medias.length"
      @close="closeLightbox()"
    />
    <popup :config="sendLeadPopupConfig" custom-width="800px">
      <template #content>
        <send-lead
          v-if="sendLeadPopupConfig.isActive"
          :property="property"
          @on-success="onSuccess"
          @close="sendLeadPopupConfig.isActive = false"
        />
      </template>
    </popup>
    <popup :config="successModalConfig" @close="onSuccessModalClosed()">
      <template #content>
        <success :text="successPopupText" />
      </template>
    </popup>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
import { PropertyMixin } from '~/units/properties/mixins/property.mixin';
import { FullScreenPopupMixin } from '~/units/core/mixins/fullScreenPopupMixin';

import FullScreenGallery from '~/components/common/FullScreenGallery.vue';
import PropertyDetailTabs from '~/components/theme2/properties/PropertyDetailTabs.vue';
import NcButton from '~/components/common/inputs/NcButton.vue';
import Popup from '~/components/common/popup/Popup.vue';
import SendLead from '~/components/common/leads/SendLead.vue';
import Success from '~/components/common/popup/Success.vue';
import { getImagesByCount } from '~/units/properties/store';
import type { Component } from '~/types';
import { usePropertiesStore } from '~/units/properties/stores';
import { type FullScreenModalType, FullScreenModalTypes } from '~/units/core/types';

export default defineNuxtComponent({
  name: 'PropertyDetailTheme2',

  mixins: [ComponentMixin, PropertyMixin, FullScreenPopupMixin],

  components: {
    Success,
    SendLead,
    Popup,
    NcButton,
    PropertyDetailTabs,
    FullScreenGallery,
  },

  async setup(props: { component: Component }) {
    const store = usePropertiesStore();
    await store.fetchSingleProperty(props.component);
  },

  computed: {
    fullScreenPopupType(): FullScreenModalType {
      return FullScreenModalTypes.GALLERY;
    },

    featuredImages() {
      if (this.property) {
        return getImagesByCount(this.property, 3);
      }
      return [];
    },

    firstImage() {
      return this.featuredImages[0];
    },

    secondImage() {
      return this.featuredImages[1];
    },

    thirdImage() {
      return this.featuredImages[2];
    },

    markerColor() {
      return this.colors['brown--secondary'];
    },

    medias() {
      return this.property?.medias;
    },
  },

  methods: {
  },

  watch: {},
});
</script>

<style scoped>
.price {
  color: var(--brown);
  font-size: 1.5rem;
}

.property-criteria__label {
  font-weight: 300;
  color: var(--brown--secondary);
  font-size: 0.8rem;
  line-height: 14.35px;
  letter-spacing: 5px;
}

.property-criteria__value {
  color: var(--dark-brown);
}

.image-container {
  position: relative;
}

.image-container img {
  display: block;
}

.overlay-item {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.first-image-overlay-item {
  position: absolute;
  bottom: 0.1rem;
  right: 0.1rem;
  transform: translate(-50%, -50%);
  text-align: right;
}

.property-image-badge {
  background-color: var(--dark-brown);
  color: var(--white);
  padding: 10px;
  font-weight: bold;
}
</style>
