<template>
  <section
    class="section-services-content our-options pt-5"
    style="background-color: var(--white)"
  >
    <div class="page-padding">
      <div class="container-large">
        <div class="padding-bottom padding-huge lg:tw-pb-12">
          <div
            class="why-box d-flex justify-content-between align-items-center"
            :class="{ 'flex-row-reverse': vars.isReverseNumber === 1 }"
            style="gap: 5rem"
          >
            <div class="w-100 image-wrapper">
              <img
                :src="vars.photoImage"
                loading="lazy"
                alt=""
                class="cover-image"
              />
            </div>
            <div class="w-100">
              <div class="subtitle" style="color: var(--brown-secondary)">
                {{ vars.orderText }}
              </div>
              <h2 class="heading-small">{{ vars.optionTitleText }}</h2>
              <div class="margin-top margin-small">
                <div class="max-width-full">
                  <p
                    v-for="(description, index) in descriptions"
                    :key="`description-${index}`"
                    class="text-size-tiny text-weight-light"
                  >
                    {{ description.paragraph }}
                  </p>
                </div>
              </div>
              <div
                v-if="vars.optionButtonText"
                class="margin-top margin-medium"
              >
                <div>
                  <nuxt-link
                    :to="vars.optionButtonUrl"
                    data-w-id="be7ec0df-c1d4-01e9-596d-897784db125e"
                    :external="true"
                    class="button outline w-inline-block"
                    style="width: 150px"
                  >
                    <div>
                      {{ vars.optionButtonText }}
                    </div>
                    <div
                      style="
                        -webkit-transform: translate3d(-102%, 0, 0)
                          scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0)
                          skew(0, 0);
                        -moz-transform: translate3d(-102%, 0, 0)
                          scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0)
                          skew(0, 0);
                        -ms-transform: translate3d(-102%, 0, 0) scale3d(1, 1, 1)
                          rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                        transform: translate3d(-102%, 0, 0) scale3d(1, 1, 1)
                          rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                      "
                      class="hover-underline"
                    ></div>
                  </nuxt-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'OurOptions',

  mixins: [ComponentMixin],

  data() {
    return {
      descriptions: [] as { [key: string]: string | number | null }[],
    };
  },

  created() {
    this.descriptions = this.groupedVariables.option_descriptions;
  },
});
</script>

<style scoped>
main > .section-services-content.our-options:first-child {
  padding-top: 10rem !important;
}

@media screen and (max-width: 991px) {
  .why-box {
    flex-wrap: wrap;
    gap: 1rem !important;
  }
  .image-wrapper {
    grid-area: 1 / 1 / 2 / 4 !important;
  }
}
</style>
