<template>
  <div
    class="section-home-projects py-5"
    style="width: 100%; background-color: var(--seashell); z-index: 999"
  >
    <div id="wrapper py-5">
      <div
        class="row g-0 d-flex flex-row justify-content-center w-100 p-xl-5 px-4 py-5"
      >
        <div class="col-12">
          <div
            class="d-flex flex-column justify-content-center align-items-center w-100"
          >
            <h2 style="color: var(--dark-brown)" class="heading-medium mb-5">
              {{ variables.boxTitleText }}
            </h2>
            <div
              class="row g-0 d-flex flex-row w-100 justify-content-center mt-3"
            >
              <div class="col-xl-2 col-12">
                <div
                  class="row g-0 d-flex flex-row align-items-center justify-content-end"
                >
                  <div
                    class="col-xxl-5 col-xl-6 col-6 g-0"
                    style="background-color: var(--white)"
                  >
                    <div
                      class="py-3 px-4 text-center text-weight-light n-cursor-pointer m-1"
                      :class="
                        searchType === PFilterSearchType.SALES ? 'm-1' : ''
                      "
                      :style="
                        searchType === PFilterSearchType.SALES
                          ? 'background-color: var(--brown); color: var(--white);'
                          : ''
                      "
                      @click="onSearchTypeClicked(PFilterSearchType.SALES)"
                    >
                      {{ variables.buyOptionText }}
                    </div>
                  </div>
                  <div
                    class="col-xxl-5 col-xl-6 col-6"
                    style="background-color: var(--white)"
                  >
                    <div
                      class="py-3 px-4 text-center text-weight-light n-cursor-pointer m-1"
                      :class="
                        searchType === PFilterSearchType.LETTINGS ? 'm-1' : ''
                      "
                      :style="
                        searchType === PFilterSearchType.LETTINGS
                          ? 'background-color: var(--brown); color: var(--white);'
                          : ''
                      "
                      @click="onSearchTypeClicked(PFilterSearchType.LETTINGS)"
                    >
                      {{ variables.rentOptionText }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="col-xl-6 col-12 form-field-wrapper d-flex flex-row justify-content-left ms-xl-2 mt-3 mt-xl-0 align-items-center"
                style="background-color: var(--white)"
              >
                <locations
                  v-model="searchInput"
                  v-model:locations="locations"
                  :is-mile-selection-visible="false"
                />
              </div>
              <div class="ms-xl-2 mt-3 mt-xl-0 col-xl-1 col-12">
                <nc-button
                  :label="variables.searchButtonText"
                  @on-click="onSearched()"
                >
                </nc-button>
              </div>
            </div>
            <div class="d-flex flex-row align-items-stretch"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
import NcButton from '~/components/common/inputs/NcButton.vue';
import Locations from '~/components/common/Locations.vue';
import { PFilterSearchType } from '~/units/properties/types';

export default defineNuxtComponent({
  name: 'PropertySearchBoxTheme2',
  components: { Locations, NcButton },

  mixins: [ComponentMixin],

  data() {
    return {
      searchInput: {
        keyword: '',
        mile: 0.25,

        is_available: false,
      },
      searchType: PFilterSearchType.SALES,

      locations: [],
    };
  },

  computed: {
    PFilterSearchType() {
      return PFilterSearchType;
    },

    overlayTitle() {
      return getVariable(this.component, 'overlay_title-html');
    },

    variables() {
      return {
        buyOptionText: getVariable(this.component, 'buy_option-text'),
        rentOptionText: getVariable(this.component, 'rent_option-text'),
        searchBoxPlaceholderText: getVariable(
          this.component,
          'searchbox_placeholder-text',
        ),
        searchButtonText: getVariable(this.component, 'search_button-text'),
        boxTitleText: getVariable(this.component, 'box_title-text'),
      };
    },
  },

  methods: {
    onSearchTypeClicked(type: PFilterSearchType) {
      this.searchType = type;
    },

    onSearched() {
      if (!this.locations.length && this.searchInput.keyword.trim()) {
        this.warningMessage('Please select a location');
        throw 'location-needed';
      }

      const url = (() => {
        if (this.searchType === PFilterSearchType.SALES) {
          return 'sales/property-for-sale';
        }
        return 'lettings/property-to-rent';
      })();

      const query: {
        locations?: any;
      } = {};
      if (this.locations.length > 0) {
        query.locations = JSON.stringify(this.locations);
      }

      this.redirectTo(
        {
          path: url,
          query,
        },
        true,
      );
    },
  },
});
</script>

<style scoped>
#wrapper {
  position: relative;
}
#wrapper video {
  position: relative;
  z-index: 100;
}
#wrapper .video-content {
  position: absolute;
  display: block;
  z-index: 150;
  left: 5%;
  bottom: 20%;
}

.form-input {
  color: var(--dark-brown);
}

.form-input::placeholder {
  color: var(--input--placeholder);
}
</style>
