<template>
  <article
    v-if="blog"
    class="nc-text mt-3"
    :style="`background-color: var(--${backgroundColor}) !important;`"
  >
    <div class="container-medium padding-top padding-huge">
      <div class="padding-vertical padding-large">
        <h1
          class="heading-small"
          style="color: var(--dark-brown); font-weight: 300"
        >
          {{ blog.title }}
        </h1>
        <div
          v-if="blog.description"
          class="empty-state w-dyn-empty mt-5"
          style="padding: 20px; color: var(--black)"
        >
          <div>
            {{ blog.description }}
          </div>
        </div>
        <div class="margin-top margin-large">
          <library-image v-if="blog.media_type === 'image'" :src="blog.largeImage">
          </library-image>
          <library-iframe v-else-if="blog.media_type === 'video'" :src="blog.video_link">
          </library-iframe>
        </div>
      </div>
    </div>
    <div class="container-medium">
      <div class="padding-vertical padding-medium">
        <div
          class="text-rich-text w-richtext"
          style="color: var(--black)"
          v-html="blog.content"
        ></div>
      </div>
      <div class="d-md-none divider-line"></div>
    </div>
  </article>
</template>

<script lang="ts">
import type { Component } from '~/types';
import { ComponentMixin } from '~/mixins/component.mixin';
import { BlogMixin } from '~/units/blogs/mixins/blog.mixin';
import { useCoreStore } from '~/units/core/store';
import { useDataFromState } from '~/composables';

export default defineNuxtComponent({
  name: 'Theme2BlogDetail',

  mixins: [ComponentMixin, BlogMixin],

  async setup(props: { component: Component }) {
    const route = useRoute();
    const slug = route.params.blog_id as string;

    if (slug) {
      await fetchBlogManager(props.component, slug);

      const { data } = useDataFromState('singleBlogRepo');
      if (
        data &&
        data[0] &&
        data[0].data &&
        data[0].data.article &&
        data[0].data.article.title
      ) {
        const coreStore = useCoreStore();
        const title = data[0].data.article.title;
        const introductionText = data[0].data.article.introduction_text;

        coreStore.updateMetaTagsGroup({
          title,
          seo_meta_title: title,
          og_meta_title: title,
          seo_meta_description: introductionText || '',
          og_meta_description: introductionText || '',
          og_meta_image: `https://lifesycle-cdn.s3.eu-west-2.amazonaws.com/${data[0].data.article.image.large}`,
        });
      }
    }
  },

  created() {
    if (process.server && !this.blog) {
      const route = useRoute();

      this.redirectTo({
        path: '/not-found',
        query: {
          path: route.path,
        },
      });
    }
  },

  computed: {
    backgroundColor() {
      return getVariable(this.component, 'background-color');
    },
  },
});
</script>

<style scoped></style>
