<template>
  <section
    class="section-services-content content-box pt-5 lg:tw-pb-12"
    :style="`background-color: var(--${vars.backgroundColor})`"
  >
    <div class="page-padding lg:tw-pt-6">
      <div class="container-large">
        <div class="padding-bottom padding-huge">
          <div
            class="why-box d-flex justify-content-between align-items-center"
            :class="{ 'flex-row-reverse': vars.isReverseNumber === 1 }"
            style="gap: 5rem"
          >
            <div class="w-100 image-wrapper">
              <NuxtImg
                v-if="vars.isImageNumber === 1"
                :src="vars.photoImage"
                loading="lazy"
                alt=""
                quality="60"
                class="cover-image"
              />
              <video-popup
                v-else
                :component="component"
                :video-source="vars.videoSourceText"
              />
            </div>
            <div class="w-100">
              <h2 class="heading-medium">{{ vars.videoTitleText }}</h2>
              <div class="margin-top margin-small">
                <div class="max-width-full">
                  <p class="text-size-tiny text-weight-light">
                    {{ vars.videoDescText }}
                  </p>
                </div>
              </div>
              <div v-if="vars.buttonUrl" class="margin-top margin-medium">
                <div>
                  <nuxt-link
                    data-w-id="bd454024-605c-912a-bc49-318b72174c6f"
                    :external="true"
                    style="
                      width: auto !important;
                      display: inline-block !important;
                    "
                    :to="vars.buttonUrl"
                    class="button w-inline-block"
                  >
                    <div class="button-label text-weight-light">
                      {{ vars.buttonText }}
                    </div>
                    <div style="height: 0%" class="overlay"></div>
                  </nuxt-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import VideoPopup from './VideoPopup.vue';
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'ContentBoxTheme2',

  mixins: [ComponentMixin],

  components: { VideoPopup },

  data() {
    return {};
  },
});
</script>

<style scoped>
.cover-image {
  max-height: 500px;
  object-fit: cover;
  object-position: center;
}

@media screen and (max-width: 991px) {
  .why-box {
    flex-wrap: wrap;
    gap: 1rem !important;
  }
  .image-wrapper {
    grid-area: 1 / 1 / 2 / 4 !important;
  }
}

::v-deep(.video-cover iframe) {
  height: 380px !important;
}
</style>
