<template>
  <div class="section-home-projects">
    <div id="wrapper">
      <NuxtImg
        :src="vars.introImage"
        quality="60"
        class="tw-max-h-[800px] lg:tw-max-h-[650px] tw-pt-24 lg:tw-pt-0"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'OfficeDetailIntro',

  mixins: [ComponentMixin],
});
</script>

<style scoped>
#wrapper {
  position: relative;
}
#wrapper img {
  position: relative;
  width: 100%;
  height: auto;
  z-index: 100;
  background-size: cover;
  object-fit: cover;
}
</style>
